<template>
    <div class="box">
        <div class="search">
           
            <el-form label-width="120px" label-height="60px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户ID：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户昵称：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户手机号：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="是否为会员：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="邀请人手机号：" prop="contactName">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" style="display: flex; justify-content: right;">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                  </el-col>
                </el-row>
              
            </el-form>
        </div>
        <div class="tab">
            <div class="add">
                <el-button
                  type="primary"
                  @click="added"
                  >新增</el-button
                >
                
            </div>
           
            <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        
        
        <el-table-column
          prop="memberId"
          label="代理商类型"
          align="center"
           width=" 200"
        >
        </el-table-column>
        <el-table-column
          prop="memberNikeName"
          label="管辖区域"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="vipLevel"
          label="代理商名称"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inviterId`"
          label="管辖店铺"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inviterName"
          label="钱包余额"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="身份证正面"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="balance"
          label="身份证反面"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime "
          label="入驻时间"
         
          align="center"
        >
        </el-table-column>
        
       
        <el-table-column
          prop=" "
          label="操作"
          width="250"
          fixed="right"
          align="center"
        >
        <template slot-scope="scope">
          <el-button type="text" @click="addp(scope.row.id)">添加店铺</el-button> 
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button> 
           
            <el-button type="text" @click="drawer=true" style="color: red;">删除</el-button> 
        </template>
        </el-table-column>
  
  
            </el-table>  
            <div class="pagination">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryInfo.currPage"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count"
              >
              </el-pagination>
            </div>
        </div> 

       <!-- 编辑弹窗 -->
       <el-dialog
          title="新增代理商"
          :visible.sync="showDialog"
          width="800px"
          height="600px"
        >
        <div class="dia">
          <el-form label-width="120px" label-height="60px">
              <el-row>
                <el-col :span="9">
                    <el-form-item label="代理商头像：" prop="contactName">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        >
                        <img v-if="from.areaImg" :src="from.areaImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="代理商名称：" prop="contactName">
                      <el-input
                        v-model="from.orderSq"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>  
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="代理商类型：" prop="contactName">
                      <el-input
                        v-model="from.categoryName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="联系电话：" prop="contactName">
                      <el-input
                        v-model="from.categoryName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="管理店铺：" prop="contactName">
                      <el-input
                        v-model="from.categoryName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="身份证正面：" prop="contactName">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        >
                        <img v-if="from.areaImg" :src="from.areaImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="9">
                    <el-form-item label="身份证反面：" prop="contactName">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        >
                        <img v-if="from.areaImg" :src="from.areaImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                </el-col>
              </el-row>
          </el-form>
         
          <div class="diafour" style="margin-left:50%;">
                <!-- <el-button type="danger" @click="showDialog =false">取消</el-button> -->
                <el-button type="primary" @click="save">保存 </el-button>
            </div>
        </div>
          
      </el-dialog>
        <!-- 详情弹窗 -->
        <el-drawer
          title="会员详情"
          :visible.sync="drawer"
          :direction="direction"
          size="1000px"
          :before-close="handleClose">
          <div class="drawer">
            <div class="drwtop">
                <div>用户ID： <span>{{ detail.memberId }}</span></div>
                <div>手机号： <span>{{ detail.phone }}</span></div>
                <div>交易总金额： <span>{{ detail.phone }}</span></div>
                <div>用户来源： <span>{{ detail.phone }}</span></div>
                <div>邀请人： <span>{{ detail.inviterName }}</span></div>
                <div>邀请人手机号： <span>{{ detail.inviterName }}</span></div>
                <div>可用积分： <span>{{ detail.point }}</span></div>
                <div>福卡余额： <span>{{ detail.balance }}</span></div>
                <div>所在城市： <span>{{ detail.address }}</span></div>
            </div>
            <div class="drwdetail">
                <div style="padding-left: 20px;">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                      <el-tab-pane label="积分明细" name="first">
                        <div>
                            <el-table
                              :data="tableData"
                              style="width: 100%"
                              
                              height="calc(100vh - 380px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="name"
                                label="序号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="关联订单编号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="交易时间"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="交易积分"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="交易类型"
                               
                                align="center"
                              >
                              </el-table-column>
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="微信充值明细" name="second">
                        <div>
                            <el-table
                              :data="tableData"
                              style="width: 100%"
                              
                              height="calc(100vh - 380px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="name"
                                label="序号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值金额"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="到账金额"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值类型"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="提交人"
                               
                                align="center"
                              >
                              </el-table-column>
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="福卡兑换明细" name="third">
                        <div>
                            <el-table
                              :data="tableData"
                              style="width: 100%"
                              
                              height="calc(100vh - 380px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="name"
                                label="序号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值金额"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值卡号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值类型"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="充值时间"
                               
                                align="center"
                              >
                              </el-table-column>
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="邀请人明细" name="fourth">
                        <div>
                            <el-table
                              :data="tableData"
                              style="width: 100%"
                              
                              height="calc(100vh - 380px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="name"
                                label="邀请人姓名"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="邀请人手机号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="获得积分"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="邀请时间"
                               
                                align="center"
                              >
                              </el-table-column>
                              
                            </el-table>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
          </div>
        </el-drawer>
        <!-- 选择店铺 -->
        <el-dialog
          title="新增店铺"
          :visible.sync="showDialogdp"
          width="800px"
          height="600px"
        >
        <div class="dia">
          <el-form label-width="120px" label-height="60px">
             
              <el-row>
                <el-col :span="24">
                    <el-form-item label="管理店铺：" prop="contactName">
                      <el-input
                        v-model="from.categoryName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
              </el-row>
             
          </el-form>
         
          <div class="diafour" style="margin-left:40%;">
                <el-button type="danger" @click="showDialog =false">取消</el-button>
                <el-button type="primary" @click="save">保存 </el-button>
            </div>
        </div>
          
      </el-dialog>
    </div>
    
  </template>
  
  <script>
  export default {
  data() {
    return {
        drawer:false,
        total: 0,
        queryInfo: {
          pageSize: 10,
          pageCount: 1,
          currPage: 1,
          condition: {}
        },
        form: {},
        showDialog:false,
        tableData:[],
        detail:{},
        from:{},
        showDialogdp:false
    };
  },
  created () {
    this.getList()
  },
  mounted() {
   
  },
  methods: {
  //获取数据
  getList () {
	  var that = this;
	  that.$http.post("/member/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
  },
  // 修改页数大小
  handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
  },

    // 获取当前页面
  handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
  },
  search(){
    this.queryInfo.condition = this.form
    this.getList()
  },
  reset(){
    this.form = {}
    this.queryInfo.condition= {}
    this.getList()
  },
  deta(id){
    this.drawer=true
    var that = this;
	  that.$http.post("/member/queryOne", {
      id:id
    }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
         
          that.detail=response.data.data.currPage
          
        }
    });
  },
  added(){
   
    this.showDialog = true;
    
  },
  addp(){
    this.showDialogdp = true;
    
  }
}
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    .search {
        background-color: #fff;
        height: 160px;
        box-sizing: border-box;
        padding: 40px;
       
    }
    .tab {
        background-color: #fff;
        margin-top: 20px;
        padding: 20px
    }
    .dia {
      .but {
            display: flex;
            justify-content: space-between;
            padding: 30px 200px;
        }
      .diali {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .liname {
            width: 100px;
        }
        input {
            height: 30px;
        }
      }
      
    }
   
  }
  .drawer {
    
    .drwtop {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        div {
            width: 300px;
            color: #000;
            height: 50px;
            span {
                color:  #494848;
            }
        }
    }
    .drwdetail {
        margin-top: 50px;
    }
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    // display: block;
  }
  </style>
  